import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export const SwalWithBootstrapButtons = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-secondary'
    },
    buttonsStyling: true
})

export const ReactSwal = withReactContent(SwalWithBootstrapButtons)
