import $ from 'jquery'
import __ from './reacts/translations'
import constant from './reacts/constants'
import { SwalWithBootstrapButtons } from './SweetAlert'

const General = {
    init: () => {
        General.inputNumber()
        General.defaultSelect()
        General.bookmark()
        General.passwordValidate()
        if ($('[data-toggle="tooltip"]').length) {
            try {
                $('[data-toggle="tooltip"]').tooltip()
            } catch (e) {}
        }

        $(document).on('click', '.fa-eye-slash', function () {
            $('#password').attr("type", 'text');

            $(this).removeClass('fa-eye-slash')
            $(this).addClass('fa-eye')
        })

        $(document).on('click', '.fa-eye', function () {
            $('#password').attr("type", 'password');

            $(this).addClass('fa-eye-slash')
            $(this).removeClass('fa-eye')
        })

        $(document).on('keydown', 'input:not(.strong-password)', function () {
            if($(this).hasClass('is-invalid')){
                $(this).removeClass('is-invalid');
                if($(this).siblings('.invalid-feedback').length){
                    $(this).siblings('.invalid-feedback').remove();
                }

                if($(this).parent().siblings('.invalid-feedback').length){
                    $(this).parent().siblings('.invalid-feedback').remove();
                }
            }
        })

        $(document).on('change', 'select', function () {
            if($(this).hasClass('is-invalid')){
                $(this).removeClass('is-invalid');
                $(this).siblings('.invalid-feedback').remove();
            }
        })
    },
    closeAlert: function () {
        $(document).on('click', '.close-action', function () {
            $($(this).data('target')).remove()
        });
    },
    passwordValidate: () => {
        $(document).on('keypress keyup', '.strong-password', General.handlePasswordValidate)
    },
    handlePasswordValidate: function (e) {
        const target = $(this).data('target')

        if (target) {
            $(target + '-be-validate').remove();
            const validationMessage = General.getValidationMessage(e.target.value)

            if (validationMessage) {
                $(target).removeClass('d-none')
                $(this).addClass('is-invalid')
                $(target).html(`<strong>${validationMessage}</strong>`)
            } else {
                $(this).removeClass('is-invalid')
                $(target).addClass('d-none')
                $(target).html('')
            }
        }
    },
    getValidationMessage: (password) => {
        if (! password.match(/[a-z]/)) {
                return __('passwordMustContainLowerCase')
        }

        if (! password.match(/[A-Z]/)) {
            return __('passwordMustContainUpperCase')
        }

        if (! password.match(/([0-9])/)) {
            return __('passwordMustContainNumber')
        }

        if (! password.match(constant.specialChars)) {
            return __('passwordMustSpecialCharacter')
        }

        if (password.length < 7) {
            return __('passwordMustLength', { length : 8 })
        }

        return null
    },
    inputNumber: () => {
        $(document).on("keypress keyup blur", 'input[type="number"]', function (e) {
            $(this).val($(this).val().replace(/[^0-9\.]/g,''));
            if ((e.which != 46 || $(this).val().indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {
                event.preventDefault();
            }
        });
    },
    bookmark: () => {
        $(document).on('click', '.btn-mark', function () {
            const url = $(this).data('url')
            axios.post(url).then(result => {
                const data = result?.data?.data
                $(this).data('url', data?.url)
                var child = $(this).children('i')

                if (data?.mark) {
                    $(child).addClass('mdi-bookmark')
                    $(child).removeClass('mdi-bookmark-outline')
                    SwalWithBootstrapButtons.fire({
                        toast: true,
                        icon: 'success',
                        position: 'bottom-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        animation: true,
                        title: __('projectSaved')
                    })
                } else {
                    $(child).addClass('mdi-bookmark-outline')
                    $(child).removeClass('mdi-bookmark')
                    SwalWithBootstrapButtons.fire({
                        toast: true,
                        icon: 'success',
                        position: 'bottom-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        animation: true,
                        title: __('projectRemoved')
                    })
                }
            })
        });
    },
    defaultSelect: () => {
        $.each($('select'), function () {
            var value = $(this).data('value')

            if (value) {
                $(this).val(value).trigger('change')
            }
        })
    }
}

$(document).ready(function() {
    General.init()
})
