import axios from 'axios';

window.AcrediusTracking = {
    sendAction: function(actionName) {
        const params = {
            action_name: actionName,
            screen_width: window.screen.width,
            screen_height: window.screen.height
        };

        axios.post(window.AcrediusTrackingUserActionsEndpoint, params);
    },
    addEventListeners: function () {
        const $this = this;
        // to track click actions just add
        // the attr "data-track-action-on-click" to the element
        // that you want to track an action on click
        // ex: <a href="#" data-track-action-on-click="clicked_some_link">Tracked link</a>
        $('[data-track-action-on-click]').on('click', function() {
            const actionName = $(this).attr('data-track-action-on-click');
            $this.sendAction(actionName);
        });


        $('[data-track-action-on-checked]').on('change', function() {
            if ($(this).is(':checked')) {
                const actionName = $(this).attr('data-track-action-on-checked');
                $this.sendAction(actionName);
            }
        });

        $('[data-track-action-on-unchecked]').on('change', function() {
            if (!$(this).is(':checked')) {
                const actionName = $(this).attr('data-track-action-on-unchecked');
                $this.sendAction(actionName);
            }
        });
    }
};
