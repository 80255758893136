const constant = {
    uri: {
        updateInvestorPersonalInformation: 'api/investor/personal-information/update'
    },
    primaryDocumentTypes: [
        {
            value: 0,
            label: 'idCard',
        },
        {
            value: 3,
            label: 'passport',
        },
        {
            value: 5,
            label: 'residencePermit',
        },
        {
            value: 11,
            label: 'drivingLicence',
        },
    ],
    secondaryDocumentTypes: [
        {
            value: 0,
            label: 'idCard',
        },
        {
            value: 3,
            label: 'passport',
        },
        {
            value: 4,
            label: 'nonEUPassport',
        },
        {
            value: 5,
            label: 'residencePermit',
        },
        {
            value: 11,
            label: 'drivingLicence',
        },
        {
            value: 15,
            label: 'familyBook',
        },
        {
            value: 16,
            label: 'taxNotice',
        }
    ],
    documentStatus: [
        'documentPutOnHold',
        'waitingForManualVerification',
        'accepted',
        'rejected',
        'rejectedUnreadableByHuman',
        'rejectedExpiredDocument',
        'rejectedWrongTypeOfDocument',
        'rejectedWrongHolderName',
        'rejectedWrongDuplicatedDocument',
    ],
    specialChars: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/
}

export default constant
