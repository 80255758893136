const __ = (key, replaceKeys = {}) => {
    let translateValue = translations?.[key] || key
    for (const index in replaceKeys) {
        translateValue = translateValue.replace(`:${index}`, replaceKeys[index])
    }

    return translateValue
}

export default __
